<template>
    <div>
        <div class="row">
            <div class="col-12 col-lg-9 mb-3">
                <div>
                    <p class="mt-3 h5 u-text-primary">Software Entwickler mit Herz <span class="text-white">●</span> nick-hat-boecker.de</p>
                    <h1><a class="link text-white" href="https://nick-hat-boecker.de">Alle haben Bock. Aber <span>Nick</span> hat <span>Böcker</span></a></h1>
                </div>
            </div>
        </div>
        <div class="mt-4 article">
            <h2 class="mb-5">Vita</h2>

            <h3>2015 - jetzt</h3>
            <p class="mb-4"><span class="job">Fullstack Software Entwickler</span> ● snoopmedia GmbH</p>

            <h3>2012 - 2015</h3>
            <p class="mb-4"><span class="job">Ausbildung zum Fachinformatiker in Anwendungsentwicklung</span> ● snoopmedia GmbH</p>

            <h3>2012</h3>
            <p><span class="job">Allgemeine Hochschulreife</span> ● Reichenbach Gymnasium Ennepetal</p>

            <div class="u-separator my-5" />

            <h2 class="mb-5">Fähigkeiten / Kenntnisse</h2>
            <ul class="list-unstyled">
                <li v-for="(tag, i) in tags" :key="`tag-${i}`" class="tag">{{ tag }}</li>
            </ul>

            <h3 class="mt-5 mb-5">Nice2Have</h3>
            <ul class="list-unstyled">
                <li v-for="(tag, i) in notSoImportantTags" :key="`nsi-tag-${i}`" class="tag">{{ tag }}</li>
            </ul>


            <div class="u-separator my-5" />

            <h2 class="mb-5">snoopmedia Projekte (Auszug)</h2>

            <p><span class="job"><a href="https://daad.de" target="_blank">daad.de</a></span> ● Frontend</p>
            <p><span class="job"><a href="https://study-in-germany.de" target="_blank">study-in-germany.de</a></span> ● Frontend / Koordination</p>
            <p><span class="job"><a href="https://myguide.de" target="_blank">my-guide.de</a></span> ● Frontend / Backend / Koordination</p>
            <p><span class="job"><a href="https://www.projekt-hero.de/" target="_blank">Projekt Hero: ease</a></span> ● App Entwicklung</p>
            <p><span class="job"><a href="https://www.projekt-hero.de/" target="_blank">Cookie Consent</a></span> ● Entwicklung eines Website & Projekt übergreifenden Cookie Layers</p>

            <div class="u-separator my-5" />

            <h2 class="mb-5">Private Projekte</h2>

            <h3>2024</h3>
            <ul>
                <li
                    v-for="(project, i) in privateProjects[2024]"
                    :key="`project-2024-${i}`"
                >
                    <span class="job">{{ project.title }}</span>
                    <span v-if="project.description"> ● {{ project.description }}</span>
                </li>
            </ul>

            <h3>2023</h3>
            <ul>
                <li
                    v-for="(project, i) in privateProjects[2023]"
                    :key="`project-2023-${i}`"
                >
                    <span class="job">{{ project.title }}</span>
                    <span v-if="project.description"> ● {{ project.description }}</span>
                </li>
            </ul>

            <h3>2022</h3>
            <ul>
                <li
                    v-for="(project, i) in privateProjects[2022]"
                    :key="`project-2022-${i}`"
                >
                    <span class="job">{{ project.title }}</span>
                    <span v-if="project.description"> ● {{ project.description }}</span>
                </li>
            </ul>

            <h3>2021</h3>
            <ul>
                <li
                    v-for="(project, i) in privateProjects[2021]"
                    :key="`project-2021-${i}`"
                >
                    <span class="job">{{ project.title }}</span>
                    <span v-if="project.description"> ● {{ project.description }}</span>
                </li>
            </ul>

            <h3>2020</h3>
            <ul>
                <li
                    v-for="(project, i) in privateProjects[2020]"
                    :key="`project-2020-${i}`"
                >
                    <span class="job">{{ project.title }}</span>
                    <span v-if="project.description"> ● {{ project.description }}</span>
                </li>
            </ul>

            <h3>Älter</h3>
            <ul>
                <li
                    v-for="(project, i) in privateProjects.older"
                    :key="`project-older-${i}`"
                >
                    <span class="job">{{ project.title }}</span>
                    <span v-if="project.description"> ● {{ project.description }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Vita',

    data: () => ({
        tags: [
            'Full-Stack-Entwicklung', 'JavaScript', 'VueJS', 'PHP', 'HTML5', 'CSS3', 'SASS / SCSS / LESS', 'Node.js', 'git', 'GitHub',
            'GitHub Pipelines', 'GitLab', 'REST', 'Docker', 'MySQL', 'Objekt Orientierte Programmierung (OOP)', 'Symfony Frameworks', 'WordPress', 'WordPress Plugins',
            'WordPress Themes', 'Webentwicklung', 'Accessibility / Barrierefreiheit (WCAG)', 'Responsive Design', 'Progressive Web App (PWA)',
            'expressJS', 'Bootstrap (Framework)', 'webpack', 'Frontend', 'Backend', 'Postman',
            'nuxtJS', 'KISS-Prinzip', 'jQuery', 'Unit Tests', 'jest', 'Cypress', 'Clean Code',
            'AdobeXD / Figma / InVision', 'Legacy Applications', 'Code Review',
        ],

        notSoImportantTags: [
            'HTML Newsletter', 'pirobase imperia (CMS)', 'Vuetify (Framework)', 'electron-vue', 'Alexa Skills', 'Evalanche', 'LimeSurvey', 'Tizen', 'electronjs', 'MacOS', 'Linux', 'Godot Engine',
            'Quasar Framework', 'Capacitor', 'Raspberry Pi', 'Portainer', 'Nginx Proxy Manager', 'Apache', 'shopify', 'Firebase', 'AppWrite', 'Supabase',
        ],

        privateProjects: {
            2024: [
                { title: 'Godot Nachschlagewerk', description: 'Vue 3 Website mit WordPress REST Anbindung' },
                { title: 'Bill Manager', description: 'Verwaltung und Export von Rechnungen. Vue 3 Website mit selfhosted AppWrite REST Anbindung' },
                { title: 'Projekt Server', description: 'Einrichtung & Konfiguration einer virtuellen VM für diverse Projekte / Staging Umgebungen / etc' },
                { title: 'Find This Pixel Anomaly', description: 'Puzzle-Game mit der Godot Engine erstellt' },
                { title: 'Puppy Rescue', description: 'Tierheim Simulation mit der Godot Engine erstellt' },
                { title: 'Teilnahme am Gamedev.js Jam 2024', description: '' },
            ],
            2023: [
                { title: 'Bonbon', description: 'Gesicherte Digitalisierung und Haltung von Dokumenten. Vue PWA mit Symfony API und Authentifizierung' },
                { title: 'ROAR', description: 'App über alle Informationen zu meinem Peugeot 2008. Vue PWA mit selfhosted AppWrite API' },
                { title: 'Shoppily', description: 'Verwalten von Einkaufslisten und Rezepten. Vue PWA mit selfhosted Appwrite API' },
                { title: 'Teilnahme am Appwrite Hackathon', description: '' },
            ],
            2022: [
                { title: 'GameDev:: Umstieg auf Godot Engine (GDScript)', description: '' },
                { title: 'DREAM Admin', description: 'Vue PWA mit Symfony API' },
                { title: 'Elgato Streamdeck:: Do not Disturb Mac', description: '' },
                { title: 'SFX Database', description: 'Vue Website und Firebase API' },
            ],
            2021: [
                { title: 'Corona-Maßnahmen-Generator', description: 'In den Top 3 der deutschen Twitter Trends' },
                { title: 'Linktree', description: 'Vue Website mit Symfony API' },
                { title: 'GameDev:: Ein Spiel mit JavaScript programmieren', description: 'Umsetzung via PhaserJS' },
                { title: 'GameDev:: Teilnahme am One-Minute-Jam', description: '' },
                { title: 'Youtube Comment Checker', description: 'Symfony Anwendung mit Anbindung an Googles YouTube API' },
                { title: 'UNO Web-Klon', description: 'Umsetzung via PhaserJS' },
                { title: 'Alexa Skill:: Pokémon', description: 'Umsetzung via PHP' },
                { title: 'Alexa Skill:: Phasmophobia', description: 'Umsetzung via PHP' },
            ],
            2020: [
                { title: 'Teilnahme am Hacktober', description: '' },
                { title: 'Perry Pocket', description: 'Vue Website und Firebase API' },
                { title: 'GTM-Doc', description: 'Symfony Anwendung mit Google API' },
                { title: 'nick-hat-boecker.de Rebrush', description: 'Vue Website' },
            ],
            older: [
                { title: 'SublimeTextPackageControlBadge', description: 'Symfony Anwendung' },
                { title: 'Host-it!', description: 'Umsetzung via electronjs' },
                { title: 'Retro Respect', description: 'Umsetzung via Plain PHP & JavaScript' },
                { title: 'Deploy-it!', description: 'Umsetzung via electronjs' },
                { title: 'Bookmark Manager (App/API/Chrome Extension)', description: 'Vue Website mit Symfony API' },
                { title: 'Xrossplayer Website', description: 'WordPress' },
                { title: 'Sublime Text:: CopyFilepathWithLineNumbers Plugin', description: 'Umsetzung via Python' },
                { title: 'Sublime Text:: SaveCopyAs Plugin', description: 'Umsetzung via Python' },
            ],
        },
    })
}
</script>

<style lang="scss" scoped>
.link {
    &:hover, &:focus, &:active {
        text-decoration: none;
        color: $primary !important;
    }
}

.job {
    color: $primary;
    font-size: 1.5rem;
}

.tag {
    border: 1px solid $primary;
    color: $primary;
    padding: $spacing-unit;
    margin: $spacing-unit;
    display: inline-block;
}
</style>
